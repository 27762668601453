$xxxs: 6px;
$xxs: 10px;
$xs: 12px;
$sm: 16px;
$md: 18px;
$lg: 20px;
$xl: 36px;
$xxl: 54px;
$xxxl: 60px;
$xxxxl: 70px;
$xxxxxl: 80px;

$margin: 0.6em;

.icon {
  display: block;
  margin: 0;

  &.marginRight {
    margin-right: $margin;
  }

  &.marginLeft {
    margin-left: $margin;
  }
}

.sizeXxxs {
  width: $xxxs;
  height: $xxxs;
}

.sizeXxs {
  width: $xxs;
  height: $xxs;
}

.sizeXs {
  width: $xs;
  height: $xs;
}

.sizeSm {
  width: $sm;
  height: $sm;
}

.sizeMd {
  width: $md;
  height: $md;
}

.sizeLg {
  width: $lg;
  height: $lg;
}

.sizeXl {
  width: $xl;
  height: $xl;
}

.sizeXxl {
  width: $xxl;
  height: $xxl;
}

.sizeXxxl {
  width: $xxxl;
  height: $xxxl;
}

.sizeXxxxl {
  width: $xxxxl;
  height: $xxxxl;
}

.sizeXxxxxl {
  width: $xxxxxl;
  height: $xxxxxl;
}
