$screen-xs-slider: 320px;
$screen-xs: 375px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1280px;
$screen-xl: 1440px;

@mixin large-and-extra-large {
  @media (min-width: calc($screen-md + 1px)) {
    @content;
  }
}

@mixin extra-large {
  @media (min-width: calc($screen-lg + 1px)) {
    @content;
  }
}

@mixin large {
  @media (min-width: calc($screen-md + 1px)) and (max-width: $screen-lg) {
    @content;
  }
}

@mixin medium {
  @media (min-width: calc($screen-sm + 1px)) and (max-width: $screen-md) {
    @content;
  }
}

@mixin small {
  @media (min-width: calc($screen-xs + 1px)) and (max-width: $screen-sm) {
    @content;
  }
}

@mixin xs {
  @media (max-width: $screen-xs) {
    @content;
  }
}

@mixin xs-slider {
  @media (max-width: $screen-xs-slider) {
    @content;
  }
}

@mixin medium-and-large {
  @media (min-width: calc($screen-sm + 1px)) {
    @content;
  }
}

@mixin small-and-medium {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin xs-and-small {
  @media (max-width: $screen-sm) {
    @content;
  }
}

@mixin slv-large {
  @media (min-width: calc($screen-xl + 1px)) {
    @content;
  }
}

@mixin slv-medium {
  @media (min-width: calc($screen-lg + 1px)) and (max-width: $screen-xl) {
    @content;
  }
}

@mixin slv-small {
  @media (max-width: $screen-lg) {
    @content;
  }
}

@mixin slv-small-and-medium {
  @media (max-width: $screen-xl) {
    @content;
  }
}

@mixin smallDesktops {
  @media (max-width: calc($screen-xl - 1px)) {
    @content;
  }
}

@mixin ipads {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin phones {
  @media (max-width: calc($screen-sm - 1px)) {
    @content;
  }
}
