$teal: #18c3bb;
$teal-border: #089d96;
$teal-link-border: #2ec8c0;
$teal-background: #7accc8;
$teal-background-light: #c4f0ee;
$teal-dark: #01645f;
$orange: #ff7801;
$pink: #d2096a;
$gray-blue: #6d97b9;
$light-green: #98cf61;
$black: #1e2022; // rgb(30, 32, 34)
$yellow-text: #ffc107;
$white: #ffffff;

$gray: #98a0b0;
$gray-text: #5c667a;
$gray-light: #777;
$gray-lighter: #b8b8b8;
$gray-lightest: #c3ccd2;
$gray-extra-light: #f3f7f9;
$gray-extra-light-home: #eaf0f5;
$gray-border: #d7dee2;
$gray-border-light: #dce0e0;
$gray-backgrounds: #d6dde1;
$gray-dark: #4f596c;
$gray-darker: #292f3b;
$gray-status: #8aaecb;
$gray-shadow: #cdcdcd;

$purple: #933cdd;
$purple-light: #d6baee;
$purple-lighter: #f6eefc;
$purple-dark: #5c667a;
$purple-status: #b791d8;

$green: #2fa205;
$green-light: #b3e8a0;
$green-extra-light: #ddf2d5;
$green-dark: #1d6b01;

$blue-light: #edfbfa;
$blue-text-secondary: #b9cbeb;
$blue-dark: #252e54;
$blue-darker: #050f39;
$blue-darkest: #001234;

$yellow-light: #fcf6ed;

$fuxia-background: #f9ebf2;
$fuxia-border: #f9cae0;
$fuxia: #e5167a;

$semi-transparent-dark-bg: rgba(#333, 0.7);
$orange-light: #ff9c44;
$orange-lighter: #ffd7b4;
$orange-dark: #9d4e09;
$silver-light: #c5c3c3;
$yellow: #ffd925;
$green-lighter: #8dc63f;
$silver-lighter: #bbbbc1;

$sorbus: #e564a2;
$red: #ff2e37;
$red-darker: #de2038;
